.mini-slideshow {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
}

.mini-slideshow-img {
    aspect-ratio: 1/1;
    width: inherit;
    position: absolute;
    left: 0;
    transition: opacity 1000ms ease;
}
