@media screen and (min-width: 800px) {
    #edit-partners {
        width: 60vw;
    }
}

@media screen and (max-width: 800px) {
    #edit-partners {
        width: calc(100vw - 6vh);
    }
}

#edit-partners-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#edit-partners {
    margin: 3vh;
    background: white;
    box-shadow: var(--box-shadow);
    min-height: 60vh;
}

#edit-partners-grid {
    padding: 3vh;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1vh;
}

#edit-partners-grid * {
    margin: 0;
}

#edit-partners-grid *:not(.buttons):not(.button) {
    width: 100%;
    text-align: left;
    user-select: none;
}

#edit-partners-grid h2 {
    font-size: 20px;
    border-bottom: 2px dotted black;
}

#edit-partners-grid h3, #edit-partners-grid input {
    font-size: 18px;
    padding: 1vh;
    margin: 0;
    font-weight: 500;
    overflow: scroll;
    white-space: nowrap;
}

#edit-partners-grid h3::-webkit-scrollbar, #edit-partners-grid input::-webkit-scrollbar {
    display: none;
}

#edit-partners-grid input:not([type='file']) {
    outline: none;
    border-bottom: 2px solid black;
}

#edit-partners-grid .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
