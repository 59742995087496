.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 1vw;
    user-select: none;
    color: black;
    background: var(--grey);
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    transition-property: color, font-weight;
    transition-duration: 200ms;
    transition-timing-function: ease;
}

.button:hover {
    color: orange;
    font-weight: 900;
}
