@media screen and (min-width: 800px) {
    .section #products {
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr;
    }
    #home-container .hero {
        flex-direction: row;
        padding: calc(15vw);
    }
    #home-container .hero h1 {
        margin-right: 6vh
    }
    #home-container .hero h1 {
        font-size: 64px
    }
}

@media screen and (max-width: 800px) {
    .section #products {
        grid-template-rows: auto autor;
        grid-template-columns: 1fr;
    }

    #home-container .hero {
        flex-direction: column-reverse;
        padding: 3vh;
    }

    #home-container .hero h1 {
        margin-top: 6vh
    }
    #home-container .hero h1 {
        font-size: 40px
    }
}

#home-container {
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#home-container .hero {
    width: 100%;
    height: 93vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../img/restaurant_diagram.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#home-container .hero h1 {
    width: 100%;
    padding: 3vh;
    background: white;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    font-weight: 600;
    animation: growIn 1s ease;
}

#home-container #hero-logo {
    width: 100%;
    aspect-ratio: 1/1
}

#home-container #hero-logo img {
    max-width: 100%;
    object-fit: contain;
}

.section #products {
    display: grid;
    align-items: center;
    gap: 2vh;
    border: 2px dashed black ;
    padding: 1vh 2vw;
    width: 100%;
}
