html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes verticalGrowIn {
  0% {
    -webkit-transform: scaleY(0%);
            transform: scaleY(0%);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: scaleY(100%);
            transform: scaleY(100%);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@keyframes verticalGrowIn {
  0% {
    -webkit-transform: scaleY(0%);
            transform: scaleY(0%);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: scaleY(100%);
            transform: scaleY(100%);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@-webkit-keyframes horizontalGrowIn {
  0% {
    -webkit-transform: scaleX(0%);
            transform: scaleX(0%);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: scaleX(100%);
            transform: scaleX(100%);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

@keyframes horizontalGrowIn {
  0% {
    -webkit-transform: scaleX(0%);
            transform: scaleX(0%);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: scaleX(100%);
            transform: scaleX(100%);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0%);
            transform: scale(0%);
  }
  100% {
    -webkit-transform: scale(100%);
            transform: scale(100%);
  }
}

@keyframes growIn {
  0% {
    -webkit-transform: scale(0%);
            transform: scale(0%);
  }
  100% {
    -webkit-transform: scale(100%);
            transform: scale(100%);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideInTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes slideInTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@-webkit-keyframes slideInBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes slideInBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@media screen and (min-width: 800px) {
  .section {
    width: 70vw;
    padding: 6vh;
  }
  .section p,
  .list li {
    font-size: 24px;
  }
  .section h1 {
    font-size: 48px;
  }
  .section h2 {
    font-size: 32px;
  }
  .section h3 {
    font-size: 24px;
  }
  .banner h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 800px) {
  .section {
    padding: 4vh;
  }
  .section p,
  .list li {
    font-size: 18px;
  }
  .section h1 {
    font-size: 30px;
  }
  .section p,
  .list li {
    font-size: 18px;
  }
  .section h2 {
    font-size: 22px;
  }
  .section h3 {
    font-size: 16px;
  }
  .banner h1 {
    font-size: 32px;
  }
}

:root {
  /* color pallette */
  --orange: #f0942b;
  --aqua: #005247;
  --sky: #c3fcf1;
  --teal: #4b8077;
  --grey: #e1e1e1;
  --gradient: linear-gradient(45deg, var(--aqua) 0%, var(--teal) 100%);
  --box-shadow: -10px 10px 50px 15px rgba(0, 0, 0, 0.3);
}

a:visited,
a {
  color: unset;
}

* {
  box-sizing: border-box;
  font-family: "Source Code", "Source Han Sans";
}

::-webkit-input-placeholder {
  color: inherit;
}

::placeholder {
  color: inherit;
}

/* HIDE RECAPTCHA BADGE*/
.grecaptcha-badge {
  opacity: 0;
}

#root {
  text-align: center;
  background: linear-gradient(45deg, #005247 0%, #4b8077 100%);
  background: var(--gradient);
}

label {
  -webkit-user-select: none;
          user-select: none;
}

#main-content {
  padding-top: 10vh;
  min-height: 92vh;
}

form {
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  background: #f0942b;
  background: var(--orange);
  width: 100%;
  padding: 5vh 5vw;
}

form div * {
  font-size: 20px;
}

form div label {
  color: white;
  text-align: right;
  padding-right: 1vw;
  width: 100%;
}

form div input {
  color: white;
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  border-radius: 0;
}

form div input:focus {
  outline: none;
}

button[type="submit"] {
  font-weight: 600;
  border: none;
  margin-top: 5vh;
  padding: 1.5vh 0;
  width: 100%;
  cursor: pointer;
  transition: all 200ms ease;
  background: white;
  font-size: 18px;
  color: black;
}

button[type="submit"]:hover {
  color: #f0942b;
  color: var(--orange);
  font-weight: 900;
}

.banner {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0942b;
  background: var(--orange);
  box-shadow: -10px 10px 50px 15px rgba(0, 0, 0, 0.3);
  box-shadow: var(--box-shadow);
}

.banner h1 {
  color: white;
  width: 100%;
  font-weight: 500;
  -webkit-animation: slideInLeft 1000ms ease;
          animation: slideInLeft 1000ms ease;
}

.banner h1 span {
  font-weight: 900;
  text-decoration: underline;
}

.section {
  font-family: "Helvetica";
  border-radius: 20px;
  margin: 3vh;
  background: white;
  box-shadow: -10px 10px 50px 15px rgba(0, 0, 0, 0.3);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition:
    opacity 750ms ease,
    -webkit-transform 750ms ease;
  transition:
    transform 750ms ease,
    opacity 750ms ease;
  transition:
    transform 750ms ease,
    opacity 750ms ease,
    -webkit-transform 750ms ease;
}

.section.hidden {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.section h1 {
  margin: 0;
  width: 100%;
  padding-bottom: 1vh;
  border-bottom: 2px dashed black;
}

.section p {
  text-align: left;
  margin: 3vh 0;
}

.section h2 {
  margin: 1vh 0;
  font-style: italic;
  font-weight: 500;
}

.section h3 {
  margin: 1vh 0;
  font-style: italic;
  font-weight: 600;
  text-align: left;
  width: 100%;
  border-bottom: 2px solid black;
}
.list li {
  text-align: left;
  list-style-position: outside;
  list-style-type: square;
}

.google-btn {
  background: white;
  padding: 2vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 8vh;
  object-fit: cover;
  flex-direction: row;
  grid-gap: 2vh;
  gap: 2vh;
}

.google-btn img {
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

.google-btn label {
  cursor: pointer;
  font-family: "Roboto Medium";
  color: black;
  display: contents;
}

@media screen and (min-width: 800px) {
    .section #products {
        grid-template-columns: 1fr 3fr;
        grid-template-rows: 1fr;
    }
    #home-container .hero {
        flex-direction: row;
        padding: calc(15vw);
    }
    #home-container .hero h1 {
        margin-right: 6vh
    }
    #home-container .hero h1 {
        font-size: 64px
    }
}

@media screen and (max-width: 800px) {
    .section #products {
        grid-template-rows: auto autor;
        grid-template-columns: 1fr;
    }

    #home-container .hero {
        flex-direction: column-reverse;
        padding: 3vh;
    }

    #home-container .hero h1 {
        margin-top: 6vh
    }
    #home-container .hero h1 {
        font-size: 40px
    }
}

#home-container {
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#home-container .hero {
    width: 100%;
    height: 93vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/static/media/restaurant_diagram.6cf5e025.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#home-container .hero h1 {
    width: 100%;
    padding: 3vh;
    background: white;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
    font-weight: 600;
    -webkit-animation: growIn 1s ease;
            animation: growIn 1s ease;
}

#home-container #hero-logo {
    width: 100%;
    aspect-ratio: 1/1
}

#home-container #hero-logo img {
    max-width: 100%;
    object-fit: contain;
}

.section #products {
    display: grid;
    align-items: center;
    grid-gap: 2vh;
    gap: 2vh;
    border: 2px dashed black ;
    padding: 1vh 2vw;
    width: 100%;
}

.mini-slideshow {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
}

.mini-slideshow-img {
    aspect-ratio: 1/1;
    width: inherit;
    position: absolute;
    left: 0;
    transition: opacity 1000ms ease;
}



#services-container {
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#quotation-button {
    margin: 0.5vh 0;
    padding: 3vh;
    font-size: 22px;
    background: black;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

#services-container h5 {
    font-size: 20px;
    margin: 1vh 0;
    border-bottom: 2px solid black;
    width: 100%;
}

#search-quotation {
    width: 100%;
    position: relative;
    font-size: 20px;
    margin: 1vh 0;
    outline: 0;
    border: 2px dotted black;
    padding: 1vh;
    text-align: center;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 1vw;
    -webkit-user-select: none;
            user-select: none;
    color: black;
    background: var(--grey);
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-weight: 600;
    transition-property: color, font-weight;
    transition-duration: 200ms;
    transition-timing-function: ease;
}

.button:hover {
    color: orange;
    font-weight: 900;
}

@media screen and (min-width: 800px) {
    #contact-container {
        grid-template-columns: 2fr 3fr;
    }
}

@media screen and (max-width: 800px) {
    #contact-container {
        grid-template-rows: 2fr 3fr;
    }
}

#contact-container {
    padding: 3vh;
    display: grid;
    grid-gap: 3vh;
    gap: 3vh;
}

#contact-form {
    -webkit-animation: slideInRight 500ms ease;
            animation: slideInRight 500ms ease;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#contact-container #info {
    padding: 5vh 5vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#info a {
    font-size: 20px;
    color: black;
    transition: 200ms all ease;
}

#info a:hover {
    color: var(--orange);
}

#info h1 {
    color: black;
    font-size: 32px
}

#info h2 {
    color: black;
    font-size: 24px;
    font-weight: 400;
}

#contact-form h1 {
    color: white;
    font-weight: 900;
}

#contact-form div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1vh 0;
    width: 100%

}

@media screen and (min-width: 800px) {
    #partners {
        width: 70vw;
    }
    #partners-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1vh;
        gap: 1vh;
    }
    .partner a img {
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}

@media screen and (max-width: 800px) {
    #partners {
        width: calc(100vw - 6vh);
    }
    #partners-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .partner:not(:first-child) {
        margin-top: 1vh;
    }
    .partner a img {
        aspect-ratio: 1.4/1;
        object-fit: cover;
    }
}

#partners-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#partners {
    margin: 3vh;
    background: white;
    padding: 3vh;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#partners h1 {
    text-align: left;
    width: 100%;
    border-bottom: 2px dotted black;
    padding: 1vh;
}

#partners-grid {
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.partner {
    width: 100%;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
    transition: 200ms ease all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border: 2px solid black;
    padding: 1vh;
    border-radius: 20px;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-name: horizontalGrowIn;
            animation-name: horizontalGrowIn;
    -webkit-animation-duration: 200ms;
            animation-duration: 200ms;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
}

.partner a img{
    cursor: pointer;
    max-width: 100%;
    transition: all 200ms ease;
    border-radius: 20px;
    width: 100%;
}

.partner h2 {
    font-size: 24px;
    margin: 1vh 0;
    font-weight: 600;
}

.partner h4 {
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}

.partner a:not(.img) {
    display: block;
    color: black;
    overflow: hidden;
    background: black;
    color: white;
    padding: 2vh;
    text-decoration: none;
    transition: all 200ms ease;
    width: 100%;
    border-radius: 20px;
}

.partner a:not(.img):hover {
    background: var(--orange)
}

@media screen and (min-width: 800px) {
  #login-form,
  #socials-login {
    width: 60vw;
  }
}

@media screen and (max-width: 800px) {
  #login-form,
  #socials-login {
    width: 90vw;
  }
}

#login-form {
  box-shadow: var(--box-shadow);
}

#login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2vh;
  width: 100%;
  grid-gap: 2vh;
  gap: 2vh;
}

#socials-login {
  background: var(--grey);
  padding: 1vh;
  box-shadow: var(--box-shadow);
}

#login-form div {
  width: 100%;
  margin: 1vh 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

#login-form p {
  color: white;
  font-size: 20px;
  padding: 1vh 1vw;
  margin-bottom: 3vh;
  font-weight: 600;
}

#login-form p.error {
  background: red;
  font-weight: 900;
}

@media screen and (min-width: 800px) {
    #main-content #back-button {
        width: 60vw;
        left: 20vw;
    }
    .action {
        font-size: 20px
    }
    #internal-container .action-section {
        width: 60vw;
    }
    #internal-container .action-section h1 {
        font-size: 32px
    }
    #notice-view-arrow {
        border-left: 24px solid var(--color);
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
    }
    .items {
        grid-template-columns: repeat(auto-fit, 18vh);
    }
    .items .action{
        width: 18vh;
    }
}

@media screen and (max-width: 800px) {
    #main-content #back-button {
        width: calc(100vw - 6vh);
        left: 3vh;
    }
    .action {
        font-size: 16px
    }
    #internal-container .action-section {
        width: calc(100vw - 6vh);
    }
    #internal-container .action-section h1 {
        font-size: 22px
    }
    #notice-view-arrow {
        border-left: 20px solid var(--color);
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
    }
    .items {
        grid-template-columns: 1fr 1fr;
    }
    .items .action {
        width: calc(50vw - 6.5vh);
    }
}

#back-button {
    margin-top: 3vh;
    position: relative;
    height: 8vh;
}

#internal-container {
    padding: 3vh 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-x: hidden
}

.items {
    display: grid;
    padding: 0 3vh;
    justify-items: center;
    justify-content: center;
    align-items: center;
    grid-gap: 1vh;
    gap: 1vh;
    margin: 1vh 0;
}

.items .action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 1/1;
    background: white;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
    user-select: none;
    padding: 3vh;
    position: relative;

    color: black;
    background: white;
    transition: all 200ms ease;
}
.items .action:hover {
    color: white;
    background: var(--orange);
}

.items .action a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.popup h2 {
    margin: 0;
    -webkit-user-select: none;
            user-select: none;
}

.popup h3 {
    margin: 0;
    font-weight: 600;
    text-align: left;
    -webkit-user-select: none;
            user-select: none
}

#internal-container .action-section {
    margin: 2vh;
    padding: 2vh;
    background: var(--grey);
    -webkit-transform: scaleY(100%);
            transform: scaleY(100%);
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
    -webkit-animation-name: verticalGrowIn;
            animation-name: verticalGrowIn;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#internal-container .action-section h1 {
    margin: 3vh 0;
}

#internal-container .action-section h2 {
    margin-top: 2vh;
    padding: 1vh;
    border-bottom: 2px solid  black;
}

#change-password-form {
    background: white;
    padding: 0;
}

#change-password-form button[type='submit'] {
    background: var(--orange) !important;
    color: white !important;
}

#change-password-form div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#change-password-form div * {
    color: black !important;
    border-color: black !important;
}

.notice-item {
    background: white;
    text-align: left;
    border-left: 4px var(--orange) solid;
    margin-left: 8px;
    transition: 500ms ease all;
    overflow: hidden;
    max-height: 1000px;
    padding: 3vh;
}

.notice-item:not(:last-child) {
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.notice-item.hidden {
    max-height: 0;
    padding: 0 3vh;
    margin-bottom: 0;
    margin-top: 0;
}

#internal-notice {
    position: relative;
}

#internal-notice h1{
    cursor: pointer;
}


.notice-item * {
    margin: 1vh 0;
}

.notice-item h3 {
    font-size: 24px
}

.notice-item h6 {
    font-size: 14px;
    font-style: italic;
}
.notice-item p {
    margin-left: 3vh;
    white-space: break-spaces
}

#notice-view-arrow {
    --color: black;
    position: absolute;
    width: 0;
    height: 0;
    top: 5vh;
    cursor: pointer;
    transition: all 200ms ease;
}

#notice-view-arrow:hover {
    --color: var(--orange)
}

#notice-view-arrow.active {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg)
}

#notice-count {
    position: absolute;
    right: 3vh;
    top: 5vh;
    margin: 0;
    height: 100%;
    font-size: 20px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.popup {
    cursor: move;
    position: absolute;
    background: white;
    box-shadow: var(--box-shadow);
    padding: 2vh;
    -webkit-animation: fadeIn 300ms ease;
            animation: fadeIn 300ms ease;
}

.content-container {
    width: 100%;
    height: 100%;
}

.cross {
    position: absolute;
    right: 0;
    top: 0;
    margin: 2vh;
    aspect-ratio: 1/1;
    cursor: pointer;
    width: 2vh;
}

.cross:after {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 3px;
    background: #9f9f9f;
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
}

.cross:before {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 3px;
    background: #9f9f9f;
    content: '';
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
}

.hover-message {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.hover-message-p {
    width: 100%;
    position: absolute;
    padding: 1vh;
    background: rgba(0,0,0,0.8);
    color: white;
    opacity: 0.0;
    transition: 100ms ease all;
    left: 0;
    margin: 0;
    font-size: 14px;
}

.hover-message-p.active {
    opacity: 1.0;
}

@media screen and (min-width: 800px) {

    #translations-message, #translations-nav, #translations-actions, #view-container {
        width: 60vw;
    }
    .translations .display {
        grid-template-columns: 1fr 4fr
    }
}
@media screen and (max-width: 800px) {
    #translations-message, #translations-nav, #translations-actions, #view-container {
        width: calc(100vw - 6vh);
    }

    .translations .display {
        grid-template-columns: 1fr 3fr
    }
}

#translations-message {
    background: white;
    padding: 1vh;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    font-style: italic;
}

#translations-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    margin-top: 3vh;
}

.translations > h3 {
    width: 100%;
    background: var(--orange);
    color: white;
    text-transform: uppercase;
    margin: 0;
    padding: 1vh 0;
    font-size: 24px;
}

#translations-actions {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid black
}

#translations-nav {
    display: flex;
    flex-direction: row;
}

#view-container {
    position: relative;
    margin-bottom: 3vh;
    transition: 200ms height ease;
}

.translations {
    width: 100%;
    position: absolute;
    opacity: 0;
    left: 0;
    transition: opacity 500ms ease;
}

.translations.active {
    opacity: 1;
    z-index: 900;
}

.translations .display {
    display: grid;
    border-top: 2px solid black;
}

.translations .display .inputs-container {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-start;
    width: 100%;
    background: var(--grey);
    transition: min-height 200ms ease;
}

.translations .display .inputs-container .inputs {
    position: absolute;
    display: none;
    grid-template-columns: 1fr 2fr 2fr;
    width: 100%;
    background: white;
    padding: 2vh;
    grid-gap: 1vh;
    gap: 1vh;
    overflow-x: scroll;
}

.translations .display .inputs-container .inputs.active {
    display: grid;
    z-index: 100;
}

.translations .display .inputs-container .inputs h6 {
    margin: auto;
    text-align: left;
    width: 100%;
    font-size: 14px
}

.translations .display .inputs-container .inputs h6:after {
    content: " > "
}

.translations .display .inputs-container .inputs input {
    height: 3vh;
    border: none;
    border-bottom: 2px solid black;
    border-radius: none;
    font-size: 14px
}

.translations .display .inputs-container .inputs input:focus {
    outline: none
}

.scopes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 2px solid black;
    height: 100%;
    background: var(--grey)
}

.scopes .scope {
    font-weight: 500;
    font-size: 16px;
    background: white;
    color: black;
    margin: 0;
    padding: 1vh;
    cursor: pointer;
    transition: all 200ms ease;
}
.scopes .scope:hover {
    color: white;
    background: var(--orange)
}

.scopes .scope.active {
    background: black;
    color: white;
}

@media screen and (min-width: 800px) {
    #users,#new-user {
        width: 60vw;
    }
    #users-list .entry .short {
        grid-template-columns: 1fr 2fr 3fr 3fr;
        grid-template-rows: 1fr;
    }
    #users-list .entry .short h2 {
        font-size: 18px;
    }
}
@media screen and (max-width: 800px) {
    #users,#new-user {
        width: calc(100vw - 6vh);
    }

    #users-list .entry .short {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr
    }
    #users-list .entry .short h2 {
        font-size: 14px;
    }
}


#users-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 70vh;
}

#users {
    margin: 3vh 0;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 70vh;
}

#users-action-bar {
    height: 7vh;
}

#new-user {
    background: white;
    margin: 3vh 0;
    padding: 3vh;
}

#new-user h1 {
    color: black;
    font-size: 20px;
    margin: 0;
    padding: 1vh;
}

#new-user h1.error {
    color: white;
    background: red;
    white-space: pre-line;
}

#new-user-form div:not(.cover):not(.switch) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1vh 0;
    align-items: center;
}

#new-user-form div button {
    grid-column: 1/3;
}

#new-user-form div input.error {
    background: red;
    border-bottom-color: red;
}

#users-list {
    border-top: 3px solid var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 2vh;
}

#users-list h1 {
    text-align: left;
    margin: 1vh 0;
    width: 100%;
    font-size: 20px;
    padding-left: 1vh;
    font-weight: 700;
    font-style: italic;
    border-bottom: 2px dashed black;
}

#users-list .entry {
    width: 100%;
    padding: 1vh;
    text-align: left;
    --currentColor: var(--grey)
}


#users-list .entry .short .arrow {
    width: 0;
    height: 0;
    border-left: 11px solid var(--currentColor);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    cursor: pointer;
    transition: all 200ms ease;
}

 #users-list .entry .short  .arrow.active {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

#users-list .entry .short{
    display: grid;
    width: 100%;
    cursor: pointer;
    grid-gap: 1vh;
    gap: 1vh;
}

#users-list .entry .short:hover {
    color: var(--orange);
    --currentColor: var(--orange)
}

#users-list .entry .short h2 {
    font-weight: normal;
    margin: 0;
    -webkit-user-select: none;
            user-select: none;
    overflow: scroll;
    white-space: nowrap;
    transition: 200ms all ease;
}

#users-list .entry .short h2::-webkit-scrollbar {
    display: none;
}

#users-list .entry .body h3 {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid white;
    margin: 0;
}

#users-list .entry .body h3.label {
    -webkit-user-select: none;
            user-select: none;
}
#users-list .entry .body {
    position: relative;
    margin-left: 5px;
    margin-top: 2vh;
    padding-left: calc(8vw - 5px);
    border-left: 2px solid var(--grey);
    display: grid;
    grid-template-columns: 1fr 3fr;
    transition: all 300ms ease;
    overflow: hidden;
    max-height: 200px;
    margin-top: 2vh;
}

#users-list .entry .body.hidden {
    max-height: 0;
    margin-top: 0vh;
}

#users-list .entry .body input {
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding: 0;
    font-style: italic
}

#users-list .entry .body input:focus {
    outline: none;
}

.delete-user {
    position: absolute;
    right: 0;
}

.delete-user .button:hover {
    background: red;
    color: white;
}

.switch {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--grey);
    padding: 1vh;
    height: 5vh;
    cursor: pointer;
    color: black;
    transition: all 200ms ease;
}

.switch:hover {
    color: var(--orange)
}

.cover {
    position: absolute;
    width: 50%;
    height: inherit;
    background: white;
    left: 50%;
    transition: all 200ms ease;
}

.cover.active {
    left: 0;
}

.switch h3 {
    margin: 0;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
    font-weight: 600;
}

@media screen and (max-width: 800px) {
    #tools {
        width: calc(100vw - 6vh)
    }
}

@media screen and (min-width: 800px) {
    #tools {
        width: 60vw;
    }

}
#tool-management-container {
    margin: 3vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#tools {
    background: white;
    padding: 3vh;
}

.group {
    margin: 2vh 0;
}

.head {
    padding: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.group > .head {
    background: var(--orange);
}

.head h1 {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    padding: 1vh;
    width: 100%;
    border: 2px solid transparent;
}

.group > .head h1 {
    border: 2px solid var(--orange);
    color: white;
}

.group > .head input {
    color: white;
}

.group > .head input {
    padding: 1vh;
    border: 2px solid white;
    outline: unset;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    height: 100%;
    border-radius: 0;
    background: none;
}

.tool .head input {
    padding: 1vh;
    outline: unset;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    height: 100%;
    margin: 0;
    border-radius: 0;
    background: none;
}

.head h3, .head a {
    text-align: left;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    width: 100%;
    padding: 1vh;
    border: 2px solid transparent;
    overflow-x: hidden;
    text-wrap: nowrap;
}

.head .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.head .buttons .button {
    text-wrap: nowrap;
}

.tool .body {
    padding: 1vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.body p{
    padding: 1vh;
    text-align: left;
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 10vh;
}

.body p[contentEditable='plaintext-only']:focus {
    outline: none;
}

.tool .body > div .color-display {
    aspect-ratio: 1/1;
    height: 5vh;
    border: 2px solid black;
}

.body input[type='color'] {
    aspect-ratio: 1/1;
    height: 5vh;
    margin: 0;
    padding: 0;
    display: block;
    background: none;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    #qrcode {
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    #qrcode {
        width: 60vw;
    }
}

#qrcode-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3vh;
    width: 100%;
}

#qrcode {
    height: 100%;
    background: white;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#qrcode-form {
    background: none;
    padding: 0;
}

#qrcode-form button[type='submit'] {
    background: var(--orange);
    color: white;
}

#qrcode-form h1 {
    padding: 1vh;
    margin: 1vh 0;
    color: black;
}

#qrcode-form div {
    width: 100%;
}

#qrcode-form h1.error {
    background: red;
    color: white;
}

#qrcode-form div label, #qrcode-form div input {
     color: black;
     border-color: black;
}

#qrcode-preview {
    margin: 3vh;
    aspect-ratio: 1/1;
    border: 2px dashed black;
    position: relative;
    min-height: 40vh;
}

#qrcode-preview img {
    object-fit: cover;
    min-height: inherit
}

#qrcode-download {
    background: rgba(240,148,43,0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    -webkit-animation: 300ms fadeIn ease;
            animation: 300ms fadeIn ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

#qrcode-download h1 {
    font-size: 24px;
    background: white;
    margin: 0;
    padding: 1vh;
}

@media screen and (min-width: 800px) {
    #contacts {
        width: 60vw;
    }
}
@media screen and (max-width: 800px) {
    #contacts {
        width: calc(100vw - 6vh);
    }
}

#contacts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3vh;
}

#contacts {
    background: white;
    min-height: 60vh;
    -webkit-animation: fadeIn 1000ms ease;
            animation: fadeIn 1000ms ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#contacts-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2vh;
}

#contacts-list .entry {
    width: 100%;
    padding: 1vh;
    --currentColor: var(--grey)
}

#contacts-list .entry .short{
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 3fr 3fr;
    align-items: center;
    transition: all 200ms ease;
    cursor: pointer;
    grid-gap: 1vh;
    gap: 1vh;
}

#contacts-list .entry .short h3 {
    font-weight: 600;
    text-align: left;
    overflow: scroll;
    text-wrap: nowrap
}

#contacts-list .entry .short h3::-webkit-scrollbar {
    display: none;
}

#contacts-list .entry .short:hover {
    color: var(--orange);
    --currentColor: var(--orange)
}

#contacts-list .entry .short .buttons {
    height: 100%;
}

#contacts-list .entry .body {
    width: 100%;
    border-left: 3px solid var(--grey);
    margin-left: 5px;
    padding-left: calc(10vh - 5px);
    max-height: 1000px;
    transition: 500ms ease all;
    overflow: hidden;
}

#contacts-list .entry .body.hidden {
    max-height: 0;
}

#contacts-list .entry .body p {
    margin: 0;
    padding: 1vh 0;
    text-align: justify;
}

#contacts-list .entry .body h2 {
    margin: 0;
    text-align: left;
    font-size: 20px
}

#contacts-list .entry .short .arrow {
    width: 0;
    height: 0;
    border-left: 11px solid var(--currentColor);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    cursor: pointer;
    transition: all 200ms ease;
}

 #contacts-list .entry .short  .arrow.active {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

#delete-button:hover {
    background: red;
    color: white;
}

@media screen and (min-width: 800px) {
    #edit-partners {
        width: 60vw;
    }
}

@media screen and (max-width: 800px) {
    #edit-partners {
        width: calc(100vw - 6vh);
    }
}

#edit-partners-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#edit-partners {
    margin: 3vh;
    background: white;
    box-shadow: var(--box-shadow);
    min-height: 60vh;
}

#edit-partners-grid {
    padding: 3vh;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1vh;
    gap: 1vh;
}

#edit-partners-grid * {
    margin: 0;
}

#edit-partners-grid *:not(.buttons):not(.button) {
    width: 100%;
    text-align: left;
    -webkit-user-select: none;
            user-select: none;
}

#edit-partners-grid h2 {
    font-size: 20px;
    border-bottom: 2px dotted black;
}

#edit-partners-grid h3, #edit-partners-grid input {
    font-size: 18px;
    padding: 1vh;
    margin: 0;
    font-weight: 500;
    overflow: scroll;
    white-space: nowrap;
}

#edit-partners-grid h3::-webkit-scrollbar, #edit-partners-grid input::-webkit-scrollbar {
    display: none;
}

#edit-partners-grid input:not([type='file']) {
    outline: none;
    border-bottom: 2px solid black;
}

#edit-partners-grid .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

@media screen and (min-width: 800px) {
    #edit-notices {
        width: 60vw;
    }
}

@media screen and (max-width: 800px) {
    #edit-notices {
        width: calc(100vw - 6vh);
    }
}

#edit-notices-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3vh;
    padding-bottom: 3vh;
}

#edit-notices {
    background: white;
}

.notice-entry {
    margin: 3vh;
    background: white;
    padding: 1vh;
    border: 2px dotted black;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.notice-entry .buttons {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.notice-entry h3, .notice-entry p, .notice-entry input {
    text-align: left;
    width: 100%;
    margin: 1vh;
    padding: 1vh;
    border: 2px solid white;
}

.notice-entry h6 {
    text-align: right;
    width: 100%;
    padding: 1vh;
    font-size: 12px;
    margin: 0;
}

.notice-entry input, .notice-entry h3 {
    font-weight: 700;
    font-size: 20px;
}

.notice-entry p {
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    white-space: break-spaces
}

.notice-entry input, .notice-entry p[contenteditable="plaintext-only"] {
    border: 2px solid black;
    outline: none;
    display: inline-block
}

.notice-entry p[contenteditable="plaintext-only"]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  color: grey;
}

#notice-action-bar {
    height: 7vh;
    border-bottom: 2px solid var(--orange);
}

#notice-guide {
    text-align: left;
    padding: 3vh 3vh 0 3vh;
    margin: 1vh 0;
}

#notice-guide li {
    list-style: square;
}

#admin-panel {
    width: 60vw;
    background: white;
    min-height: 60vh;
    max-height: 80vh;
    display: flex;
    flex-direction: row;
}

#admin-container {
    margin-top: 3vh;
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
#admin-actions {
    border-right: 2px solid var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    background: #4d4d4d;
}

#admin-actions .button {
    max-height: 7vh;
}

#admin-result {
    overflow-y: scroll;
    max-height: 100%;
    width: 100%;
}

#admin-result h3 {
    margin: 1vh;
    padding: 1vh;
    border-bottom: 2px dotted black;
}

#admin-result p {
    white-space: break-spaces;
    text-align: left;
    font-size: 10px;
    background: white;
    padding: 1vh 3vh;
    overflow-wrap: break-word;
    width: 100%;
}

#admin-result p span.error {
    background: red;
    color: white;
}

#admin-result p span.warning {
    background: yellow;
}

#admin-result p span.http {
    background: blue;
    color: white;
}

#privacy-policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#privacy-policy-container .section {
    -webkit-animation: slideInLeft 1000ms ease;
            animation: slideInLeft 1000ms ease;
}

#privacy-policy-container .section * {
    width: 100%;
}

#privacy-policy-container .section h1 {
    font-size: 30px;
}

#privacy-policy-container .section h2 {
    font-size: 24px;
}

#privacy-policy-container .section p {
    margin: 1vh 0;
    font-size: 16px;
}

#privacy-policy-container .section ul li {
    list-style: square;
    text-align: left;
}

@media screen and (min-width: 800px) {
    #send-email {
        width: 60vw
    }
    #recipient-list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    #send-email {
        width: calc(100vw - 6vh)
    }
    #recipient-list {
        grid-template-columns: 1fr;
    }
}

#send-email-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 3vh;
}

#send-email {
    padding: 3vh;
    background: white;
}

#send-email > h3 {
    margin: 1vh 0;
}

#send-email-grid label {
    text-align: left;
    position: relative;
}

#send-email-grid label::after {
    content: ':';
}

#send-email-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1vh;
    gap: 1vh;
}

#send-email-grid input, #send-email-grid p {
    padding: 1vh;
    border: 2px solid black;
    margin: 0;
    text-align: left;
}

#send-email-grid input:focus, #send-email-grid p:focus {
    outline: none;
}

#recipient-list {
    display: grid;
    grid-gap: 1vh;
    gap: 1vh;
    margin: 3vh 0;
}

#recipient-list .recipient {
    padding: 1vh;
    background: var(--grey)
}

#recipient-list .recipient.error {
    background: red;
    color: white;
    font-weight: 700;
}

#send-button {
    margin-top: 1vh;
    grid-column: 1 / 3
}

@media screen and (min-width: 800px) {
    #quotation{
        width: 60vw
    }
    #quotation-errors {
        width: 70vw;
        left: calc(15vw)
    }
    #pages .page .input-grid h6, #pages .page .input-grid input {
        font-size: 18px
    }
    #navigation h3 {
        font-size: 18px
    }
    #pages .page h1 {
        font-size: 24px
    }
    .input-item-body p {
        font-size: 14px
    }
}

@media screen and (max-width: 800px) {
    #quotation, #quotation-errors {
        width: calc(100vw - 6vh)
    }
    #quotation-errors {
        left: 3vh;
    }
    #pages .page .input-grid h6, #pages .page .input-grid input {
        font-size: 14px
    }
    #quotation > h1 {
        font-size: 24px;
    }
    #navigation h3 {
        font-size: 16px
    }
    #pages .page h1 {
        font-size: 20px
    }
    .input-item-body p {
        font-size: 10px
    }
}

#quotation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3vh;
    height: 82vh;
    position: relative;
}

#quotation {
    -webkit-animation: fadeIn 500ms ease;
            animation: fadeIn 500ms ease;
    background: white;
    padding: 3vh;
    border-radius: 20px;
    height: 100%
}

#quotation > h1 {
    border-bottom: 2px dotted black;
    padding: 1vh;
    margin: 1vh 0;
}

#navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow-x: hidden;
    text-wrap: nowrap;
}

#navigation h3 {
    padding: 1.5vh;
    font-weight: 600;
    overflow: hidden;
    cursor: pointer;
    transition: all 200ms ease;
    background: var(--grey);
    margin: 0 1vh;
    text-wrap: nowrap;
    width: 100%;
    border-radius: 10px;
    white-space: nowrap
}

#navigation h3:hover {
    overflow: visible;
    color: var(--orange)
}

#navigation h3.active {
    overflow: visible;
    color: white;
    background: var(--orange)
}

#pages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3vh 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 2px dotted black;
    border-radius: 20px;
}

#pages .page {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: all 500ms ease;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    overflow-y: scroll
}

#pages .page.left {
    visibility: hidden;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
}

#pages .page.right {
    visibility: hidden;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
}

#pages .page h1 {
    margin: 0;
    padding: 1vh;
}

#pages .page .input-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 100%;
    margin: 1vh 0;
    padding: 2vh;
}

#nav-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1vh;
    gap: 1vh;
}

#nav-buttons .button {
    -webkit-animation: 500ms fadeIn ease;
            animation: 500ms fadeIn ease
}

.input-item-body {
    grid-column: 1/3;
    max-height: 0;
    height: 100%;
    transition: all 500ms ease-in-out;
    opacity: 0;
    margin-left: 3vh;
    visibility: hidden;
}

.input-item-body h3 {
    width: 100%;
    margin: 0;
    text-align: left;
    font-style: italic;
    font-weight: 700;
    font-size: 16px
}

.input-item-body p {
    text-align: left;
    margin: 1vh 0;
}

.input-item-body.active {
    margin: 1vh;
    margin-left: 3vh;
    opacity: 1;
    max-height: 500px;
    visibility: visible
}

#pages .page .input-grid h6, #pages .page .input-grid input {
    width: 100%;
    margin: 0;
    text-align: left;
    padding: 1vh;
    height: 100%;
}

#pages .page .input-grid h6:after {
    content: ':'
}

#pages .page .input-grid input {
    border-radius: 0;
    outline: 0;
    border: 0;
    border-bottom: 2px solid black;
}

.input-grid p[contenteditable='plaintext-only'] {
    text-align: left;
    outline: none;
    border: 2px solid black;
    padding: 1vh;
    grid-column: 1/3;
}

#quotation-errors {
    background: red;
    color: white;
    transition: 300ms all ease;
    opacity: 0;
    margin-bottom: 0;
    border-radius: 20px;
    position: absolute;
    top: 3vh;
    box-shadow: var(--box-shadow)
}

#quotation-errors h3 {
    margin: 3vh 0;
    font-style: italic;
    text-decoration: underline
}

#quotation-errors.active {
    opacity: 1;
}

@media screen and (min-width: 800px) {
    #product-management {
        width: 60vw
    }
}

@media screen and (max-width: 800px) {
    #product-management {
        width: calc(100vw - 6vh)
    }
}

#product-management-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 3vh 0;
}

#category-nav {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--orange)
}

#categories {
    width: 100%;
    padding: 1vh 3vh 3vh 3vh;
    position: relative;
    background: white;
}

.category-view {
    grid-gap: 1vh;
    gap: 1vh;
    display: none;
    grid-template-columns: 2fr 1fr 1fr 1fr;
}

.category-view.active {
    display: grid;
}

.category-view .buttons {
    display: flex;
    flex-direction: row;
}

.category-view h2 {
    font-size: 18px;
    text-align: left;
    margin: 1vh 0;
}

.category-view input, .category-view h3 {
    margin: auto;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid white;
}

.category-view input {
    border: none;
    border-bottom: 2px solid black;
    font-style: italic;
    border-radius: 0;
}

.category-view input:focus {
    outline: unset;
}

.price::before {
    content: '$';
    color: green;
    font-weight: 900;
    display: inline-block;
}

.category-title {
    grid-column: 1/4;
    margin: 1vh 0;
    border-bottom: 2px dotted black;
    font-size: 24px;
}

#product-management-info {
    padding: 2vh;
    margin: 0;
    text-align: justify;
    background: white;
    font-size: 16px;
    font-style: italic;
}

@media screen and (min-width: 800px)  {
    #quotation-result {
        width: 70vw
    }
}

@media screen and (max-width: 800px)  {
    #quotation-result {
        width: calc(100vw - 6vh)
    }
}

#quotation-result-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3vh;
    height: 100%;
}

#quotation-result {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3vh;
    min-height: 84vh;
    background: white;
    border-radius: 20px;
    box-shadow: var(--box-shadow)
}

#quotation-result h2 {
    margin: 1vh 0;
    width: 100%;
}

#quotation-result h3 {
    margin: 1vh 0;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

#quotation-result p {
    text-align: left;
    font-style: italic;
    width: 100%;
    margin: 1vh 0;
}

#quotation-result .buttons {
    display: flex;
    flex-direction: row;
    grid-gap: 1vh;
    gap: 1vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1vh 0;
}

#quotation-pdf {
    height: 100%;
    width: 100%;
}

#quotation-result > div > a {
    background: black;
    text-decoration: none;
    color: white;
    width: 100%;
    border-radius: 20px;
    display: block;
    padding: 2vh;
    font-size: 20px;
    margin: 3vh 0;
}

#verify-quotation h1 {
}

#verify-quotation a {
    background: black;
    border-radius: 20px;
    color: white;
    padding: 3vh;
    width: 100%;
    text-decoration: none;
    font-size: 20px;
    transition: all 200ms ease;
}

#verify-quotation a:hover {
    background: var(--orange)
}

#verify-quotation {
    grid-gap: 3vh;
    gap: 3vh;
    border-radius: 20px;
    background: white;
    margin: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh;
}

@media screen and (min-width: 800px) {
    #lang-menu {
        width: 10vw;
        top: 9vh;
        position: absolute;
    }
}

@media screen and (max-width: 800px) {
    #lang-menu {
        width: inherit
    }
}

#lang-switch-btn {
    background: var(--grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 200ms ease;
}

#lang-switch-btn p {
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

#lang-switch-btn:hover {
    color: orange;
}

#lang-menu {
    background: var(--grey);
    display: grid;
    grid-template-columns: 1fr;
}

.lang-item {
    padding: 2vh 0;
    cursor: pointer;
    transition: all 200ms ease;
}
.lang-item:hover {
    color: orange;
}

#lang-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 100%;
}

header {
    position: fixed;
    z-index: 1000;
}

@media screen and (min-width: 800px) {
    #header {
        grid-template-columns: 3fr 7fr;
    }
    #nav-links a {
        font-size: 24px
    }
    #nav-links {
        justify-content: right;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

@media screen and (max-width: 800px) {
    #header {
        grid-template-columns: 3fr 1fr;
    }
    #nav-links a {
        font-size: 18px;
    }
    #nav-links {
        justify-content: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

#header {
    box-shadow: 0px 1px 20px rgba(0,0,0,0.2);
    position: absolute;
    padding: 1vh 2vw;
    background: white;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
    display: inline-grid;
    width: 100vw;
    top: 0;
    z-index: 100;
    display: inline-grid;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
    gap: 1vw;
    z-index: 1000;
}

#logo-container {
    display: flex;
    justify-content: left;
    align-items: center;
}

#logo {
    height: 8vh;
    margin-left: 1vw;
}

#nav-links {
    display: grid;
    align-items: center;
}

#nav-links a {
    padding: 2vh 1vw;
    text-decoration: none;
    transition: all 200ms ease;
    color: black;
    border-bottom: 2px solid white;
    font-weight: 600;
    width: 100%;
}

#nav-links a:hover {
    color: var(--orange);
    border-color: var(--orange);
    font-weight: 900;
}

#nav-links .button {
    margin: 0 2vw;
    width: 10vw;
}

#header-items{
    display: grid;
    grid-template-columns: auto 10vw 10vw;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
    gap: 1vw;
}

#burger-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
}

#burger-overlay {
    position: absolute;
    background: rgba(0,0,0,0.2);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-animation: fadeIn 1s ease;
            animation: fadeIn 1s ease;
    width: 100vw;
    height: 100vh;
    top: -1vh;
    right: -2vw;
}

#burger-button {
    cursor: pointer;
    aspect-ratio: 1/1;
    height: 5vh;
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 2vw;
    z-index: 100;
}

#burger-button .line:after {
    display: block;
    position: relative;
    top: 50%;
    width: 100%;
    height: 3px;
    content: '';
    background: black;
}

#burger-menu {
    top: -1vh;
    right: -2vw;
    position: absolute;
    height: 100vh;
    width: 50vw;
    background: white;
    -webkit-animation: slideInRight 500ms ease;
            animation: slideInRight 500ms ease;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#burger-items {
    padding-top: 10vh;
    display: grid;
    grid-template-rows: 3fr 1fr auto;
    grid-gap: 2vh;
    gap: 2vh;
    width: 100%;
}

@media screen and (min-width: 800px) {
    #footer {
        padding: 3vh 15vw;
    }
}

@media screen and (max-width: 800px) {
    #footer {
        padding: 3vh;
    }
}

footer {
    position: relative;
    z-index: 1000;
}

#footer {
    box-shadow: 0px -1px 20px rgba(0,0,0,0.2);
    background: white;
    width: 100%;
    z-index: 1000;
}

#footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footer-container > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

#footer-container > div * {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}

#footer-section-left {
    text-align: left;
}

#footer-section-right {
    text-align: right;
}

