@media screen and (min-width: 800px) {
    #edit-notices {
        width: 60vw;
    }
}

@media screen and (max-width: 800px) {
    #edit-notices {
        width: calc(100vw - 6vh);
    }
}

#edit-notices-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3vh;
    padding-bottom: 3vh;
}

#edit-notices {
    background: white;
}

.notice-entry {
    margin: 3vh;
    background: white;
    padding: 1vh;
    border: 2px dotted black;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.notice-entry .buttons {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.notice-entry h3, .notice-entry p, .notice-entry input {
    text-align: left;
    width: 100%;
    margin: 1vh;
    padding: 1vh;
    border: 2px solid white;
}

.notice-entry h6 {
    text-align: right;
    width: 100%;
    padding: 1vh;
    font-size: 12px;
    margin: 0;
}

.notice-entry input, .notice-entry h3 {
    font-weight: 700;
    font-size: 20px;
}

.notice-entry p {
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    white-space: break-spaces
}

.notice-entry input, .notice-entry p[contenteditable="plaintext-only"] {
    border: 2px solid black;
    outline: none;
    display: inline-block
}

.notice-entry p[contenteditable="plaintext-only"]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  color: grey;
}

#notice-action-bar {
    height: 7vh;
    border-bottom: 2px solid var(--orange);
}

#notice-guide {
    text-align: left;
    padding: 3vh 3vh 0 3vh;
    margin: 1vh 0;
}

#notice-guide li {
    list-style: square;
}
