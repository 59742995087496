#admin-panel {
    width: 60vw;
    background: white;
    min-height: 60vh;
    max-height: 80vh;
    display: flex;
    flex-direction: row;
}

#admin-container {
    margin-top: 3vh;
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
#admin-actions {
    border-right: 2px solid var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    background: #4d4d4d;
}

#admin-actions .button {
    max-height: 7vh;
}

#admin-result {
    overflow-y: scroll;
    max-height: 100%;
    width: 100%;
}

#admin-result h3 {
    margin: 1vh;
    padding: 1vh;
    border-bottom: 2px dotted black;
}

#admin-result p {
    white-space: break-spaces;
    text-align: left;
    font-size: 10px;
    background: white;
    padding: 1vh 3vh;
    overflow-wrap: break-word;
    width: 100%;
}

#admin-result p span.error {
    background: red;
    color: white;
}

#admin-result p span.warning {
    background: yellow;
}

#admin-result p span.http {
    background: blue;
    color: white;
}
