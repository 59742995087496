@keyframes verticalGrowIn {
  0% {
    transform: scaleY(0%);
    transform-origin: top;
  }
  100% {
    transform: scaleY(100%);
    transform-origin: top;
  }
}

@keyframes horizontalGrowIn {
  0% {
    transform: scaleX(0%);
    transform-origin: left;
  }
  100% {
    transform: scaleX(100%);
    transform-origin: left;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (min-width: 800px) {
  .section {
    width: 70vw;
    padding: 6vh;
  }
  .section p,
  .list li {
    font-size: 24px;
  }
  .section h1 {
    font-size: 48px;
  }
  .section h2 {
    font-size: 32px;
  }
  .section h3 {
    font-size: 24px;
  }
  .banner h1 {
    font-size: 48px;
  }
}

@media screen and (max-width: 800px) {
  .section {
    padding: 4vh;
  }
  .section p,
  .list li {
    font-size: 18px;
  }
  .section h1 {
    font-size: 30px;
  }
  .section p,
  .list li {
    font-size: 18px;
  }
  .section h2 {
    font-size: 22px;
  }
  .section h3 {
    font-size: 16px;
  }
  .banner h1 {
    font-size: 32px;
  }
}

:root {
  /* color pallette */
  --orange: #f0942b;
  --aqua: #005247;
  --sky: #c3fcf1;
  --teal: #4b8077;
  --grey: #e1e1e1;
  --gradient: linear-gradient(45deg, var(--aqua) 0%, var(--teal) 100%);
  --box-shadow: -10px 10px 50px 15px rgba(0, 0, 0, 0.3);
}

a:visited,
a {
  color: unset;
}

* {
  box-sizing: border-box;
  font-family: "Source Code", "Source Han Sans";
}

::placeholder {
  color: inherit;
}

/* HIDE RECAPTCHA BADGE*/
.grecaptcha-badge {
  opacity: 0;
}

#root {
  text-align: center;
  background: var(--gradient);
}

label {
  user-select: none;
}

#main-content {
  padding-top: 10vh;
  min-height: 92vh;
}

form {
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  background: var(--orange);
  width: 100%;
  padding: 5vh 5vw;
}

form div * {
  font-size: 20px;
}

form div label {
  color: white;
  text-align: right;
  padding-right: 1vw;
  width: 100%;
}

form div input {
  color: white;
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  border-radius: 0;
}

form div input:focus {
  outline: none;
}

button[type="submit"] {
  font-weight: 600;
  border: none;
  margin-top: 5vh;
  padding: 1.5vh 0;
  width: 100%;
  cursor: pointer;
  transition: all 200ms ease;
  background: white;
  font-size: 18px;
  color: black;
}

button[type="submit"]:hover {
  color: var(--orange);
  font-weight: 900;
}

.banner {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange);
  box-shadow: var(--box-shadow);
}

.banner h1 {
  color: white;
  width: 100%;
  font-weight: 500;
  animation: slideInLeft 1000ms ease;
}

.banner h1 span {
  font-weight: 900;
  text-decoration: underline;
}

.section {
  font-family: "Helvetica";
  border-radius: 20px;
  margin: 3vh;
  background: white;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 99;
  opacity: 1;
  transform: translateX(0);
  transition:
    transform 750ms ease,
    opacity 750ms ease;
}

.section.hidden {
  opacity: 0;
  transform: translateX(-100%);
}

.section h1 {
  margin: 0;
  width: 100%;
  padding-bottom: 1vh;
  border-bottom: 2px dashed black;
}

.section p {
  text-align: left;
  margin: 3vh 0;
}

.section h2 {
  margin: 1vh 0;
  font-style: italic;
  font-weight: 500;
}

.section h3 {
  margin: 1vh 0;
  font-style: italic;
  font-weight: 600;
  text-align: left;
  width: 100%;
  border-bottom: 2px solid black;
}
.list li {
  text-align: left;
  list-style-position: outside;
  list-style-type: square;
}

.google-btn {
  background: white;
  padding: 2vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 8vh;
  object-fit: cover;
  flex-direction: row;
  gap: 2vh;
}

.google-btn img {
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.google-btn label {
  cursor: pointer;
  font-family: "Roboto Medium";
  color: black;
  display: contents;
}
