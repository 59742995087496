@media screen and (min-width: 800px) {
    #users,#new-user {
        width: 60vw;
    }
    #users-list .entry .short {
        grid-template-columns: 1fr 2fr 3fr 3fr;
        grid-template-rows: 1fr;
    }
    #users-list .entry .short h2 {
        font-size: 18px;
    }
}
@media screen and (max-width: 800px) {
    #users,#new-user {
        width: calc(100vw - 6vh);
    }

    #users-list .entry .short {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr
    }
    #users-list .entry .short h2 {
        font-size: 14px;
    }
}


#users-container {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 70vh;
}

#users {
    margin: 3vh 0;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 70vh;
}

#users-action-bar {
    height: 7vh;
}

#new-user {
    background: white;
    margin: 3vh 0;
    padding: 3vh;
}

#new-user h1 {
    color: black;
    font-size: 20px;
    margin: 0;
    padding: 1vh;
}

#new-user h1.error {
    color: white;
    background: red;
    white-space: pre-line;
}

#new-user-form div:not(.cover):not(.switch) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1vh 0;
    align-items: center;
}

#new-user-form div button {
    grid-column: 1/3;
}

#new-user-form div input.error {
    background: red;
    border-bottom-color: red;
}

#users-list {
    border-top: 3px solid var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 2vh;
}

#users-list h1 {
    text-align: left;
    margin: 1vh 0;
    width: 100%;
    font-size: 20px;
    padding-left: 1vh;
    font-weight: 700;
    font-style: italic;
    border-bottom: 2px dashed black;
}

#users-list .entry {
    width: 100%;
    padding: 1vh;
    text-align: left;
    --currentColor: var(--grey)
}


#users-list .entry .short .arrow {
    width: 0;
    height: 0;
    border-left: 11px solid var(--currentColor);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    cursor: pointer;
    transition: all 200ms ease;
}

 #users-list .entry .short  .arrow.active {
    transform: rotate(90deg);
}

#users-list .entry .short{
    display: grid;
    width: 100%;
    cursor: pointer;
    gap: 1vh;
}

#users-list .entry .short:hover {
    color: var(--orange);
    --currentColor: var(--orange)
}

#users-list .entry .short h2 {
    font-weight: normal;
    margin: 0;
    user-select: none;
    overflow: scroll;
    white-space: nowrap;
    transition: 200ms all ease;
}

#users-list .entry .short h2::-webkit-scrollbar {
    display: none;
}

#users-list .entry .body h3 {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid white;
    margin: 0;
}

#users-list .entry .body h3.label {
    user-select: none;
}
#users-list .entry .body {
    position: relative;
    margin-left: 5px;
    margin-top: 2vh;
    padding-left: calc(8vw - 5px);
    border-left: 2px solid var(--grey);
    display: grid;
    grid-template-columns: 1fr 3fr;
    transition: all 300ms ease;
    overflow: hidden;
    max-height: 200px;
    margin-top: 2vh;
}

#users-list .entry .body.hidden {
    max-height: 0;
    margin-top: 0vh;
}

#users-list .entry .body input {
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding: 0;
    font-style: italic
}

#users-list .entry .body input:focus {
    outline: none;
}

.delete-user {
    position: absolute;
    right: 0;
}

.delete-user .button:hover {
    background: red;
    color: white;
}
