@media screen and (max-width: 800px) {
    #tools {
        width: calc(100vw - 6vh)
    }
}

@media screen and (min-width: 800px) {
    #tools {
        width: 60vw;
    }

}
#tool-management-container {
    margin: 3vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#tools {
    background: white;
    padding: 3vh;
}

.group {
    margin: 2vh 0;
}

.head {
    padding: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.group > .head {
    background: var(--orange);
}

.head h1 {
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    padding: 1vh;
    width: 100%;
    border: 2px solid transparent;
}

.group > .head h1 {
    border: 2px solid var(--orange);
    color: white;
}

.group > .head input {
    color: white;
}

.group > .head input {
    padding: 1vh;
    border: 2px solid white;
    outline: unset;
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    height: 100%;
    border-radius: 0;
    background: none;
}

.tool .head input {
    padding: 1vh;
    outline: unset;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    height: 100%;
    margin: 0;
    border-radius: 0;
    background: none;
}

.head h3, .head a {
    text-align: left;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
    width: 100%;
    padding: 1vh;
    border: 2px solid transparent;
    overflow-x: hidden;
    text-wrap: nowrap;
}

.head .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.head .buttons .button {
    text-wrap: nowrap;
}

.tool .body {
    padding: 1vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.body p{
    padding: 1vh;
    text-align: left;
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 10vh;
}

.body p[contentEditable='plaintext-only']:focus {
    outline: none;
}

.tool .body > div .color-display {
    aspect-ratio: 1/1;
    height: 5vh;
    border: 2px solid black;
}

.body input[type='color'] {
    aspect-ratio: 1/1;
    height: 5vh;
    margin: 0;
    padding: 0;
    display: block;
    background: none;
    cursor: pointer;
}
