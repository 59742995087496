.popup {
    cursor: move;
    position: absolute;
    background: white;
    box-shadow: var(--box-shadow);
    padding: 2vh;
    animation: fadeIn 300ms ease;
}

.content-container {
    width: 100%;
    height: 100%;
}

.cross {
    position: absolute;
    right: 0;
    top: 0;
    margin: 2vh;
    aspect-ratio: 1/1;
    cursor: pointer;
    width: 2vh;
}

.cross:after {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 3px;
    background: #9f9f9f;
    content: '';
    transform: rotate(45deg)
}

.cross:before {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 3px;
    background: #9f9f9f;
    content: '';
    transform: rotate(-45deg)
}
