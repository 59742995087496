@media screen and (min-width: 800px)  {
    #quotation-result {
        width: 70vw
    }
}

@media screen and (max-width: 800px)  {
    #quotation-result {
        width: calc(100vw - 6vh)
    }
}

#quotation-result-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3vh;
    height: 100%;
}

#quotation-result {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3vh;
    min-height: 84vh;
    background: white;
    border-radius: 20px;
    box-shadow: var(--box-shadow)
}

#quotation-result h2 {
    margin: 1vh 0;
    width: 100%;
}

#quotation-result h3 {
    margin: 1vh 0;
    width: 100%;
    text-align: left;
    font-weight: 500;
}

#quotation-result p {
    text-align: left;
    font-style: italic;
    width: 100%;
    margin: 1vh 0;
}

#quotation-result .buttons {
    display: flex;
    flex-direction: row;
    gap: 1vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1vh 0;
}

#quotation-pdf {
    height: 100%;
    width: 100%;
}

#quotation-result > div > a {
    background: black;
    text-decoration: none;
    color: white;
    width: 100%;
    border-radius: 20px;
    display: block;
    padding: 2vh;
    font-size: 20px;
    margin: 3vh 0;
}
