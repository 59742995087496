@media screen and (max-width: 800px) {
    #qrcode {
        width: 100%;
    }
}

@media screen and (min-width: 800px) {
    #qrcode {
        width: 60vw;
    }
}

#qrcode-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3vh;
    width: 100%;
}

#qrcode {
    height: 100%;
    background: white;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#qrcode-form {
    background: none;
    padding: 0;
}

#qrcode-form button[type='submit'] {
    background: var(--orange);
    color: white;
}

#qrcode-form h1 {
    padding: 1vh;
    margin: 1vh 0;
    color: black;
}

#qrcode-form div {
    width: 100%;
}

#qrcode-form h1.error {
    background: red;
    color: white;
}

#qrcode-form div label, #qrcode-form div input {
     color: black;
     border-color: black;
}

#qrcode-preview {
    margin: 3vh;
    aspect-ratio: 1/1;
    border: 2px dashed black;
    position: relative;
    min-height: 40vh;
}

#qrcode-preview img {
    object-fit: cover;
    min-height: inherit
}

#qrcode-download {
    background: rgba(240,148,43,0.75);
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    animation: 300ms fadeIn ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

#qrcode-download h1 {
    font-size: 24px;
    background: white;
    margin: 0;
    padding: 1vh;
}
