@media screen and (min-width: 800px) {
  #login-form,
  #socials-login {
    width: 60vw;
  }
}

@media screen and (max-width: 800px) {
  #login-form,
  #socials-login {
    width: 90vw;
  }
}

#login-form {
  box-shadow: var(--box-shadow);
}

#login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2vh;
  width: 100%;
  gap: 2vh;
}

#socials-login {
  background: var(--grey);
  padding: 1vh;
  box-shadow: var(--box-shadow);
}

#login-form div {
  width: 100%;
  margin: 1vh 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

#login-form p {
  color: white;
  font-size: 20px;
  padding: 1vh 1vw;
  margin-bottom: 3vh;
  font-weight: 600;
}

#login-form p.error {
  background: red;
  font-weight: 900;
}
