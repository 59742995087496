

#services-container {
    padding-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#quotation-button {
    margin: 0.5vh 0;
    padding: 3vh;
    font-size: 22px;
    background: black;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
    width: 100%;
}

#services-container h5 {
    font-size: 20px;
    margin: 1vh 0;
    border-bottom: 2px solid black;
    width: 100%;
}

#search-quotation {
    width: 100%;
    position: relative;
    font-size: 20px;
    margin: 1vh 0;
    outline: 0;
    border: 2px dotted black;
    padding: 1vh;
    text-align: center;
}
