@media screen and (min-width: 800px) {
    #quotation{
        width: 60vw
    }
    #quotation-errors {
        width: 70vw;
        left: calc(15vw)
    }
    #pages .page .input-grid h6, #pages .page .input-grid input {
        font-size: 18px
    }
    #navigation h3 {
        font-size: 18px
    }
    #pages .page h1 {
        font-size: 24px
    }
    .input-item-body p {
        font-size: 14px
    }
}

@media screen and (max-width: 800px) {
    #quotation, #quotation-errors {
        width: calc(100vw - 6vh)
    }
    #quotation-errors {
        left: 3vh;
    }
    #pages .page .input-grid h6, #pages .page .input-grid input {
        font-size: 14px
    }
    #quotation > h1 {
        font-size: 24px;
    }
    #navigation h3 {
        font-size: 16px
    }
    #pages .page h1 {
        font-size: 20px
    }
    .input-item-body p {
        font-size: 10px
    }
}

#quotation-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3vh;
    height: 82vh;
    position: relative;
}

#quotation {
    animation: fadeIn 500ms ease;
    background: white;
    padding: 3vh;
    border-radius: 20px;
    height: 100%
}

#quotation > h1 {
    border-bottom: 2px dotted black;
    padding: 1vh;
    margin: 1vh 0;
}

#navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    overflow-x: hidden;
    text-wrap: nowrap;
}

#navigation h3 {
    padding: 1.5vh;
    font-weight: 600;
    overflow: hidden;
    cursor: pointer;
    transition: all 200ms ease;
    background: var(--grey);
    margin: 0 1vh;
    text-wrap: nowrap;
    width: 100%;
    border-radius: 10px;
    white-space: nowrap
}

#navigation h3:hover {
    overflow: visible;
    color: var(--orange)
}

#navigation h3.active {
    overflow: visible;
    color: white;
    background: var(--orange)
}

#pages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3vh 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 2px dotted black;
    border-radius: 20px;
}

#pages .page {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    opacity: 1;
    transform: translateX(0%);
    transition: all 500ms ease;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    overflow-y: scroll
}

#pages .page.left {
    visibility: hidden;
    transform: translateX(-100%);
    opacity: 0;
}

#pages .page.right {
    visibility: hidden;
    transform: translateX(100%);
    opacity: 0;
}

#pages .page h1 {
    margin: 0;
    padding: 1vh;
}

#pages .page .input-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 100%;
    margin: 1vh 0;
    padding: 2vh;
}

#nav-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vh;
}

#nav-buttons .button {
    animation: 500ms fadeIn ease
}

.input-item-body {
    grid-column: 1/3;
    max-height: 0;
    height: 100%;
    transition: all 500ms ease-in-out;
    opacity: 0;
    margin-left: 3vh;
    visibility: hidden;
}

.input-item-body h3 {
    width: 100%;
    margin: 0;
    text-align: left;
    font-style: italic;
    font-weight: 700;
    font-size: 16px
}

.input-item-body p {
    text-align: left;
    margin: 1vh 0;
}

.input-item-body.active {
    margin: 1vh;
    margin-left: 3vh;
    opacity: 1;
    max-height: 500px;
    visibility: visible
}

#pages .page .input-grid h6, #pages .page .input-grid input {
    width: 100%;
    margin: 0;
    text-align: left;
    padding: 1vh;
    height: 100%;
}

#pages .page .input-grid h6:after {
    content: ':'
}

#pages .page .input-grid input {
    border-radius: 0;
    outline: 0;
    border: 0;
    border-bottom: 2px solid black;
}

.input-grid p[contenteditable='plaintext-only'] {
    text-align: left;
    outline: none;
    border: 2px solid black;
    padding: 1vh;
    grid-column: 1/3;
}

#quotation-errors {
    background: red;
    color: white;
    transition: 300ms all ease;
    opacity: 0;
    margin-bottom: 0;
    border-radius: 20px;
    position: absolute;
    top: 3vh;
    box-shadow: var(--box-shadow)
}

#quotation-errors h3 {
    margin: 3vh 0;
    font-style: italic;
    text-decoration: underline
}

#quotation-errors.active {
    opacity: 1;
}
