.switch {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: var(--grey);
    padding: 1vh;
    height: 5vh;
    cursor: pointer;
    color: black;
    transition: all 200ms ease;
}

.switch:hover {
    color: var(--orange)
}

.cover {
    position: absolute;
    width: 50%;
    height: inherit;
    background: white;
    left: 50%;
    transition: all 200ms ease;
}

.cover.active {
    left: 0;
}

.switch h3 {
    margin: 0;
    width: 100%;
    user-select: none;
    font-weight: 600;
}
