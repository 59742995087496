.hover-message {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.hover-message-p {
    width: 100%;
    position: absolute;
    padding: 1vh;
    background: rgba(0,0,0,0.8);
    color: white;
    opacity: 0.0;
    transition: 100ms ease all;
    left: 0;
    margin: 0;
    font-size: 14px;
}

.hover-message-p.active {
    opacity: 1.0;
}
