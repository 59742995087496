#burger-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
}

#burger-overlay {
    position: absolute;
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(5px);
    animation: fadeIn 1s ease;
    width: 100vw;
    height: 100vh;
    top: -1vh;
    right: -2vw;
}

#burger-button {
    cursor: pointer;
    aspect-ratio: 1/1;
    height: 5vh;
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 2vw;
    z-index: 100;
}

#burger-button .line:after {
    display: block;
    position: relative;
    top: 50%;
    width: 100%;
    height: 3px;
    content: '';
    background: black;
}

#burger-menu {
    top: -1vh;
    right: -2vw;
    position: absolute;
    height: 100vh;
    width: 50vw;
    background: white;
    animation: slideInRight 500ms ease;
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#burger-items {
    padding-top: 10vh;
    display: grid;
    grid-template-rows: 3fr 1fr auto;
    gap: 2vh;
    width: 100%;
}
