#privacy-policy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#privacy-policy-container .section {
    animation: slideInLeft 1000ms ease;
}

#privacy-policy-container .section * {
    width: 100%;
}

#privacy-policy-container .section h1 {
    font-size: 30px;
}

#privacy-policy-container .section h2 {
    font-size: 24px;
}

#privacy-policy-container .section p {
    margin: 1vh 0;
    font-size: 16px;
}

#privacy-policy-container .section ul li {
    list-style: square;
    text-align: left;
}
