@media screen and (min-width: 800px) {
    #lang-menu {
        width: 10vw;
        top: 9vh;
        position: absolute;
    }
}

@media screen and (max-width: 800px) {
    #lang-menu {
        width: inherit
    }
}

#lang-switch-btn {
    background: var(--grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 200ms ease;
}

#lang-switch-btn p {
    cursor: pointer;
    user-select: none;
}

#lang-switch-btn:hover {
    color: orange;
}

#lang-menu {
    background: var(--grey);
    display: grid;
    grid-template-columns: 1fr;
}

.lang-item {
    padding: 2vh 0;
    cursor: pointer;
    transition: all 200ms ease;
}
.lang-item:hover {
    color: orange;
}

#lang-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 100%;
}
