@media screen and (min-width: 800px) {
    #contacts {
        width: 60vw;
    }
}
@media screen and (max-width: 800px) {
    #contacts {
        width: calc(100vw - 6vh);
    }
}

#contacts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 3vh;
}

#contacts {
    background: white;
    min-height: 60vh;
    animation: fadeIn 1000ms ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#contacts-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2vh;
}

#contacts-list .entry {
    width: 100%;
    padding: 1vh;
    --currentColor: var(--grey)
}

#contacts-list .entry .short{
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 3fr 3fr;
    align-items: center;
    transition: all 200ms ease;
    cursor: pointer;
    gap: 1vh;
}

#contacts-list .entry .short h3 {
    font-weight: 600;
    text-align: left;
    overflow: scroll;
    text-wrap: nowrap
}

#contacts-list .entry .short h3::-webkit-scrollbar {
    display: none;
}

#contacts-list .entry .short:hover {
    color: var(--orange);
    --currentColor: var(--orange)
}

#contacts-list .entry .short .buttons {
    height: 100%;
}

#contacts-list .entry .body {
    width: 100%;
    border-left: 3px solid var(--grey);
    margin-left: 5px;
    padding-left: calc(10vh - 5px);
    max-height: 1000px;
    transition: 500ms ease all;
    overflow: hidden;
}

#contacts-list .entry .body.hidden {
    max-height: 0;
}

#contacts-list .entry .body p {
    margin: 0;
    padding: 1vh 0;
    text-align: justify;
}

#contacts-list .entry .body h2 {
    margin: 0;
    text-align: left;
    font-size: 20px
}

#contacts-list .entry .short .arrow {
    width: 0;
    height: 0;
    border-left: 11px solid var(--currentColor);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    cursor: pointer;
    transition: all 200ms ease;
}

 #contacts-list .entry .short  .arrow.active {
    transform: rotate(90deg);
}

#delete-button:hover {
    background: red;
    color: white;
}
