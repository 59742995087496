@media screen and (min-width: 800px) {
    #partners {
        width: 70vw;
    }
    #partners-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1vh;
    }
    .partner a img {
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}

@media screen and (max-width: 800px) {
    #partners {
        width: calc(100vw - 6vh);
    }
    #partners-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .partner:not(:first-child) {
        margin-top: 1vh;
    }
    .partner a img {
        aspect-ratio: 1.4/1;
        object-fit: cover;
    }
}

#partners-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#partners {
    margin: 3vh;
    background: white;
    padding: 3vh;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#partners h1 {
    text-align: left;
    width: 100%;
    border-bottom: 2px dotted black;
    padding: 1vh;
}

#partners-grid {
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.partner {
    width: 100%;
    position: relative;
    user-select: none;
    transition: 200ms ease all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border: 2px solid black;
    padding: 1vh;
    border-radius: 20px;
    animation-direction: normal;
    animation-fill-mode: backwards;
    animation-name: horizontalGrowIn;
    animation-duration: 200ms;
    animation-timing-function: ease;
}

.partner a img{
    cursor: pointer;
    max-width: 100%;
    transition: all 200ms ease;
    border-radius: 20px;
    width: 100%;
}

.partner h2 {
    font-size: 24px;
    margin: 1vh 0;
    font-weight: 600;
}

.partner h4 {
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
}

.partner a:not(.img) {
    display: block;
    color: black;
    overflow: hidden;
    background: black;
    color: white;
    padding: 2vh;
    text-decoration: none;
    transition: all 200ms ease;
    width: 100%;
    border-radius: 20px;
}

.partner a:not(.img):hover {
    background: var(--orange)
}
