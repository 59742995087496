header {
    position: fixed;
    z-index: 1000;
}

@media screen and (min-width: 800px) {
    #header {
        grid-template-columns: 3fr 7fr;
    }
    #nav-links a {
        font-size: 24px
    }
    #nav-links {
        justify-content: right;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

@media screen and (max-width: 800px) {
    #header {
        grid-template-columns: 3fr 1fr;
    }
    #nav-links a {
        font-size: 18px;
    }
    #nav-links {
        justify-content: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

#header {
    box-shadow: 0px 1px 20px rgba(0,0,0,0.2);
    position: absolute;
    padding: 1vh 2vw;
    background: white;
    transition: transform 500ms ease;
    display: inline-grid;
    width: 100vw;
    top: 0;
    z-index: 100;
    display: inline-grid;
    grid-template-rows: 1fr;
    gap: 1vw;
    z-index: 1000;
}

#logo-container {
    display: flex;
    justify-content: left;
    align-items: center;
}

#logo {
    height: 8vh;
    margin-left: 1vw;
}

#nav-links {
    display: grid;
    align-items: center;
}

#nav-links a {
    padding: 2vh 1vw;
    text-decoration: none;
    transition: all 200ms ease;
    color: black;
    border-bottom: 2px solid white;
    font-weight: 600;
    width: 100%;
}

#nav-links a:hover {
    color: var(--orange);
    border-color: var(--orange);
    font-weight: 900;
}

#nav-links .button {
    margin: 0 2vw;
    width: 10vw;
}

#header-items{
    display: grid;
    grid-template-columns: auto 10vw 10vw;
    grid-template-rows: 1fr;
    gap: 1vw;
}
