@media screen and (min-width: 800px) {
    #footer {
        padding: 3vh 15vw;
    }
}

@media screen and (max-width: 800px) {
    #footer {
        padding: 3vh;
    }
}

footer {
    position: relative;
    z-index: 1000;
}

#footer {
    box-shadow: 0px -1px 20px rgba(0,0,0,0.2);
    background: white;
    width: 100%;
    z-index: 1000;
}

#footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footer-container > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

#footer-container > div * {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
}

#footer-section-left {
    text-align: left;
}

#footer-section-right {
    text-align: right;
}
