@media screen and (min-width: 800px) {
    #contact-container {
        grid-template-columns: 2fr 3fr;
    }
}

@media screen and (max-width: 800px) {
    #contact-container {
        grid-template-rows: 2fr 3fr;
    }
}

#contact-container {
    padding: 3vh;
    display: grid;
    gap: 3vh;
}

#contact-form {
    animation: slideInRight 500ms ease;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#contact-container #info {
    padding: 5vh 5vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#info a {
    font-size: 20px;
    color: black;
    transition: 200ms all ease;
}

#info a:hover {
    color: var(--orange);
}

#info h1 {
    color: black;
    font-size: 32px
}

#info h2 {
    color: black;
    font-size: 24px;
    font-weight: 400;
}

#contact-form h1 {
    color: white;
    font-weight: 900;
}

#contact-form div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1vh 0;
    width: 100%

}
