@media screen and (min-width: 800px) {
    #send-email {
        width: 60vw
    }
    #recipient-list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 800px) {
    #send-email {
        width: calc(100vw - 6vh)
    }
    #recipient-list {
        grid-template-columns: 1fr;
    }
}

#send-email-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 3vh;
}

#send-email {
    padding: 3vh;
    background: white;
}

#send-email > h3 {
    margin: 1vh 0;
}

#send-email-grid label {
    text-align: left;
    position: relative;
}

#send-email-grid label::after {
    content: ':';
}

#send-email-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1vh;
}

#send-email-grid input, #send-email-grid p {
    padding: 1vh;
    border: 2px solid black;
    margin: 0;
    text-align: left;
}

#send-email-grid input:focus, #send-email-grid p:focus {
    outline: none;
}

#recipient-list {
    display: grid;
    gap: 1vh;
    margin: 3vh 0;
}

#recipient-list .recipient {
    padding: 1vh;
    background: var(--grey)
}

#recipient-list .recipient.error {
    background: red;
    color: white;
    font-weight: 700;
}

#send-button {
    margin-top: 1vh;
    grid-column: 1 / 3
}
