@media screen and (min-width: 800px) {
    #product-management {
        width: 60vw
    }
}

@media screen and (max-width: 800px) {
    #product-management {
        width: calc(100vw - 6vh)
    }
}

#product-management-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 3vh 0;
}

#category-nav {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid var(--orange)
}

#categories {
    width: 100%;
    padding: 1vh 3vh 3vh 3vh;
    position: relative;
    background: white;
}

.category-view {
    gap: 1vh;
    display: none;
    grid-template-columns: 2fr 1fr 1fr 1fr;
}

.category-view.active {
    display: grid;
}

.category-view .buttons {
    display: flex;
    flex-direction: row;
}

.category-view h2 {
    font-size: 18px;
    text-align: left;
    margin: 1vh 0;
}

.category-view input, .category-view h3 {
    margin: auto;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid white;
}

.category-view input {
    border: none;
    border-bottom: 2px solid black;
    font-style: italic;
    border-radius: 0;
}

.category-view input:focus {
    outline: unset;
}

.price::before {
    content: '$';
    color: green;
    font-weight: 900;
    display: inline-block;
}

.category-title {
    grid-column: 1/4;
    margin: 1vh 0;
    border-bottom: 2px dotted black;
    font-size: 24px;
}

#product-management-info {
    padding: 2vh;
    margin: 0;
    text-align: justify;
    background: white;
    font-size: 16px;
    font-style: italic;
}
