#verify-quotation h1 {
}

#verify-quotation a {
    background: black;
    border-radius: 20px;
    color: white;
    padding: 3vh;
    width: 100%;
    text-decoration: none;
    font-size: 20px;
    transition: all 200ms ease;
}

#verify-quotation a:hover {
    background: var(--orange)
}

#verify-quotation {
    gap: 3vh;
    border-radius: 20px;
    background: white;
    margin: 3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh;
}
