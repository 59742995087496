@media screen and (min-width: 800px) {

    #translations-message, #translations-nav, #translations-actions, #view-container {
        width: 60vw;
    }
    .translations .display {
        grid-template-columns: 1fr 4fr
    }
}
@media screen and (max-width: 800px) {
    #translations-message, #translations-nav, #translations-actions, #view-container {
        width: calc(100vw - 6vh);
    }

    .translations .display {
        grid-template-columns: 1fr 3fr
    }
}

#translations-message {
    background: white;
    padding: 1vh;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    font-style: italic;
}

#translations-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    margin-top: 3vh;
}

.translations > h3 {
    width: 100%;
    background: var(--orange);
    color: white;
    text-transform: uppercase;
    margin: 0;
    padding: 1vh 0;
    font-size: 24px;
}

#translations-actions {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid black
}

#translations-nav {
    display: flex;
    flex-direction: row;
}

#view-container {
    position: relative;
    margin-bottom: 3vh;
    transition: 200ms height ease;
}

.translations {
    width: 100%;
    position: absolute;
    opacity: 0;
    left: 0;
    transition: opacity 500ms ease;
}

.translations.active {
    opacity: 1;
    z-index: 900;
}

.translations .display {
    display: grid;
    border-top: 2px solid black;
}

.translations .display .inputs-container {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-start;
    width: 100%;
    background: var(--grey);
    transition: min-height 200ms ease;
}

.translations .display .inputs-container .inputs {
    position: absolute;
    display: none;
    grid-template-columns: 1fr 2fr 2fr;
    width: 100%;
    background: white;
    padding: 2vh;
    gap: 1vh;
    overflow-x: scroll;
}

.translations .display .inputs-container .inputs.active {
    display: grid;
    z-index: 100;
}

.translations .display .inputs-container .inputs h6 {
    margin: auto;
    text-align: left;
    width: 100%;
    font-size: 14px
}

.translations .display .inputs-container .inputs h6:after {
    content: " > "
}

.translations .display .inputs-container .inputs input {
    height: 3vh;
    border: none;
    border-bottom: 2px solid black;
    border-radius: none;
    font-size: 14px
}

.translations .display .inputs-container .inputs input:focus {
    outline: none
}

.scopes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 2px solid black;
    height: 100%;
    background: var(--grey)
}

.scopes .scope {
    font-weight: 500;
    font-size: 16px;
    background: white;
    color: black;
    margin: 0;
    padding: 1vh;
    cursor: pointer;
    transition: all 200ms ease;
}
.scopes .scope:hover {
    color: white;
    background: var(--orange)
}

.scopes .scope.active {
    background: black;
    color: white;
}
