@media screen and (min-width: 800px) {
    #main-content #back-button {
        width: 60vw;
        left: 20vw;
    }
    .action {
        font-size: 20px
    }
    #internal-container .action-section {
        width: 60vw;
    }
    #internal-container .action-section h1 {
        font-size: 32px
    }
    #notice-view-arrow {
        border-left: 24px solid var(--color);
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
    }
    .items {
        grid-template-columns: repeat(auto-fit, 18vh);
    }
    .items .action{
        width: 18vh;
    }
}

@media screen and (max-width: 800px) {
    #main-content #back-button {
        width: calc(100vw - 6vh);
        left: 3vh;
    }
    .action {
        font-size: 16px
    }
    #internal-container .action-section {
        width: calc(100vw - 6vh);
    }
    #internal-container .action-section h1 {
        font-size: 22px
    }
    #notice-view-arrow {
        border-left: 20px solid var(--color);
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
    }
    .items {
        grid-template-columns: 1fr 1fr;
    }
    .items .action {
        width: calc(50vw - 6.5vh);
    }
}

#back-button {
    margin-top: 3vh;
    position: relative;
    height: 8vh;
}

#internal-container {
    padding: 3vh 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-x: hidden
}

.items {
    display: grid;
    padding: 0 3vh;
    justify-items: center;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    margin: 1vh 0;
}

.items .action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 1/1;
    background: white;
    user-select: none;
    cursor: pointer;
    user-select: none;
    padding: 3vh;
    position: relative;

    color: black;
    background: white;
    transition: all 200ms ease;
}
.items .action:hover {
    color: white;
    background: var(--orange);
}

.items .action a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.popup h2 {
    margin: 0;
    user-select: none;
}

.popup h3 {
    margin: 0;
    font-weight: 600;
    text-align: left;
    user-select: none
}

#internal-container .action-section {
    margin: 2vh;
    padding: 2vh;
    background: var(--grey);
    transform: scaleY(100%);
    animation-direction: normal;
    animation-fill-mode: backwards;
    animation-name: verticalGrowIn;
    animation-duration: 300ms;
    animation-timing-function: ease;
    box-shadow: var(--box-shadow);
    border-radius: 20px;
}

#internal-container .action-section h1 {
    margin: 3vh 0;
}

#internal-container .action-section h2 {
    margin-top: 2vh;
    padding: 1vh;
    border-bottom: 2px solid  black;
}

#change-password-form {
    background: white;
    padding: 0;
}

#change-password-form button[type='submit'] {
    background: var(--orange) !important;
    color: white !important;
}

#change-password-form div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#change-password-form div * {
    color: black !important;
    border-color: black !important;
}

.notice-item {
    background: white;
    text-align: left;
    border-left: 4px var(--orange) solid;
    margin-left: 8px;
    transition: 500ms ease all;
    overflow: hidden;
    max-height: 1000px;
    padding: 3vh;
}

.notice-item:not(:last-child) {
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.notice-item.hidden {
    max-height: 0;
    padding: 0 3vh;
    margin-bottom: 0;
    margin-top: 0;
}

#internal-notice {
    position: relative;
}

#internal-notice h1{
    cursor: pointer;
}


.notice-item * {
    margin: 1vh 0;
}

.notice-item h3 {
    font-size: 24px
}

.notice-item h6 {
    font-size: 14px;
    font-style: italic;
}
.notice-item p {
    margin-left: 3vh;
    white-space: break-spaces
}

#notice-view-arrow {
    --color: black;
    position: absolute;
    width: 0;
    height: 0;
    top: 5vh;
    cursor: pointer;
    transition: all 200ms ease;
}

#notice-view-arrow:hover {
    --color: var(--orange)
}

#notice-view-arrow.active {
    transform: rotate(90deg)
}

#notice-count {
    position: absolute;
    right: 3vh;
    top: 5vh;
    margin: 0;
    height: 100%;
    font-size: 20px;
    user-select: none;
    cursor: pointer;
}
